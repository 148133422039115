import React from 'react';
import { CONVERSATION_SENDER } from '../../resources/constants/content';
import ChatBubble from './ChatBubble';
import ChatChoices from './ChatChoices';

const ChatBubbleContainer = ({ bubblesContainer, showMessageIndex }) => {
    const isRobot = bubblesContainer[0]?.by === CONVERSATION_SENDER.ROBOT;
    const choices = bubblesContainer[bubblesContainer.length - 1].choices;

    return (
        <div className={`messages-container ${isRobot ? 'left' : 'right'}`}>
            <div className='sender-profile transition'><div className='icon'></div></div>

            <div className='messages'>
                {bubblesContainer.map(m => <ChatBubble key={m.id} message={m} />)}
            </div>

            {choices && choices.length ? <ChatChoices choices={choices} messageId={bubblesContainer[bubblesContainer.length - 1].id} showMessageIndex={showMessageIndex} /> : null}
        </div>
    );
}

export default ChatBubbleContainer;
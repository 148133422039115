import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-scroll';
import Typist from 'react-typist';
import { MENU } from './resources/constants/menu';

const Home = ({ t, homeBackgroundRef, homeHighlight1Ref }) => {
    const [typing, setTyping] = useState(true);

    useEffect(() => {
        setTyping(true);
    }, [typing]);

    const handleTypindEnd = () => {
        setTyping(false);
    };

    return (
        <>
            <section className="fixed-container">
                <svg className={'home-wave'} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 924.87 1147.854">
                    <path id="Path_3" data-name="Path 3" d="M1021,1149s199.79-571.789,502.805-651.889S1872.332,1.145,1872.332,1.145h73.537V1149Z" transform="translate(-1021 -1.145)" fill="#fff" />
                </svg>
                <div className="human"></div>

                <div className="home-banner container">
                    <div className="home-message">
                        <h2>Hi, I'm Clément 👋🏻</h2>

                        <h1 className="title">
                            <span className="highlighter selected">
                                <div className="text">
                                    {typing && (
                                        <Typist cursor={{ show: false }} onTypingDone={handleTypindEnd}>
                                            Web &amp; Mobile
                                            <Typist.Backspace count={12} delay={3000} />
                                            FullStack
                                            <Typist.Backspace count={9} delay={3000} />
                                            UX &amp; UI
                                            <Typist.Backspace count={14} delay={3000} />
                                            Entrepreneur
                                            <Typist.Backspace count={12} delay={3000} />
                                            Passionate
                                            <Typist.Backspace count={10} delay={3000} />
                                        </Typist>
                                    )}
                                </div>
                                <div className="highlight transition" ref={homeHighlight1Ref}></div>
                            </span>
                            <span>developer based in Belgium. 🇧🇪</span>
                        </h1>

                        <div className="call-to-actions flex align">
                            <Link to={MENU[4].id} smooth={true} duration={500}>
                                <button className="default primary rounded-s transition">
                                    <span className="main-text transition">
                                        {t('Hiring me')} <span className="emoji">✋🏻</span>
                                    </span>
                                </button>
                            </Link>

                            <Link to={MENU[1].id} smooth={true} duration={500} className="highlighter">
                                <span className="text">{t('See works')}</span>
                                <div className="highlight transition"></div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="mouse-scroll-down">
                    <svg width="100%" height="100%" viewBox="0 0 247 390" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <path id="wheel" d="M123.359,79.775l0,72.843" />
                        <path id="mouse" d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z" />
                    </svg>
                </div>
            </section>
            <div className="blur-background" ref={homeBackgroundRef}></div>
        </>
    );
};

export default withNamespaces()(Home);

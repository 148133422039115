import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Element } from 'react-scroll';
import { DESIGN_SKILLS, PROGRAMMING_SKILLS } from '../resources/constants/content';
import { MENU } from '../resources/constants/menu';
import ItemSkillPanel from './skill/ItemSkillPanel';

const SkillPanel = ({ blurValue, t, skillPanelRef, skillOverlay1Ref, skillOverlay2Ref, skillHighlight1Ref, skillHighlight2Ref }) => {
    return (
        <section className='page no-scroll-bar' ref={skillPanelRef}>
            <Element name={MENU[2].id}>
                <div className='content'>
                    <div className='title container flex align'>
                        <h1>
                            <div className='line'>
                                <div className='white-line-effect'>
                                    <div className='overlay transition' ref={skillOverlay1Ref}></div>
                                </div>
                                <span className='highlighter selected'><span className='text'>{t("Skills")}</span><div className='highlight transition' ref={skillHighlight1Ref}></div></span> <span className='text'>{t("I practice")},</span><br />
                            </div>

                            <div className='line'>
                                <div className='white-line-effect'>
                                    <div className='overlay transition' ref={skillOverlay2Ref}></div>
                                </div>
                                <span className='highlighter selected'><span className='text'>{t("Tools")}</span><div className='highlight transition' ref={skillHighlight2Ref}></div></span> <span className='text'>{t("I use")}.</span>
                            </div>
                        </h1>
                    </div>

                    <div className='container'>
                        <div className='skills'>
                            {PROGRAMMING_SKILLS.map((skill, index) => <ItemSkillPanel blurValue={blurValue} key={skill.name} skill={skill} position={index} showItems={blurValue > 0} />)}
                        </div>

                        <div className='skills'>
                            {DESIGN_SKILLS.map((skill, index) => <ItemSkillPanel key={skill.name} skill={skill} position={index + (PROGRAMMING_SKILLS.length + 1)} showItems={blurValue > 0} />)}
                        </div>
                    </div>
                </div>
            </Element>
        </section>
    );
}

export default withNamespaces()(SkillPanel);
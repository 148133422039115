import React from 'react';
import propTypes from 'prop-types';

const ItemContactPanel = ({ contact, showItems, position }) => {
    return (
        <a href={contact.link} target={'_blank'} rel="noreferrer" className={`shadow rounded-s opacity-fade-effect ${showItems ? 'visible' : ''}`} style={{ transitionDelay: `${position * 0.1}s` }}>
            <img src={contact.icon} alt={contact.id} />
        </a>
    );
}

ItemContactPanel.propTypes = {
    contact: propTypes.object.isRequired
};

export default ItemContactPanel;
import propTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import YouTube from 'react-youtube';

const Slider = ({ backgroundColor, images, t }) => {
    return (
        <div className="slider-container rounded-l" style={{ background: backgroundColor }}>
            <Carousel showStatus={false} showThumbs={false} showIndicators={true} infiniteLoop={true} swipeable={true} showArrows={true}>
                {images?.map((i, index) => (
                    <div key={index} className={`item rounded-m ${i.youtube ? 'youtube' : ''}`}>
                        {i.image && <img src={i.image} className="rounded-m" alt="project" style={i.type ? { objectFit: i.type, maxHeight: '100%', width: 'auto' } : null} />}
                        {i.video && <video src={i.video} controls={true}></video>}
                        {i.youtube && <YouTube videoId={i} opts={{ width: '100%', height: '100%' }} />}
                        {i.label && (
                            <div className="info shadow">
                                <div className="icon"></div>
                                <span>{t(i.label)}</span>
                            </div>
                        )}
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

Slider.propTypes = {
    backgroundColor: propTypes.string.isRequired,
    images: propTypes.array.isRequired,
};

export default withNamespaces()(Slider);

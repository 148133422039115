import React from 'react';
import { withNamespaces } from 'react-i18next';

const ChatBubble = ({ message, t }) => {
    if (!message.message)
        return null;

    return (
        <div className='message-container'>
            <div className='chat-bubble rounded-m transition'>
                {message.messageIcon ? <div className='icon' style={{ background: `url(${message.messageIcon})` }}></div> : null}

                {message.link ? <a href={message.link} target={'_blank'} rel="noreferrer">{t(message.message)}</a> : t(message.message)}
            </div>
        </div>
    );
}

export default withNamespaces()(ChatBubble);
import React from 'react';
import propTypes from 'prop-types';

const ItemSkillPanel = ({ skill, showItems, position }) => {
    return (
        <div className={`skill rounded-m shadow opacity-fade-effect ${showItems ? 'visible' : ''}`} style={{transitionDelay: `${position * 0.1}s`}}>
            <img src={skill.logo} alt={skill.name}></img>
            <span>{skill.name}</span>

            <div className={`progress ${skill.level}`}>
                <div className='bar'></div>
                <div className='bar'></div>
                <div className='bar'></div>
            </div>
        </div>
    );
}

ItemSkillPanel.propTypes = {
    skill: propTypes.object.isRequired
};

export default ItemSkillPanel;
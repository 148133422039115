import AWS from '../../resources/images/AWS.png';
import Azure from '../../resources/images/azure.png';
import Dotnet from '../../resources/images/dotnet.png';
import email from '../../resources/images/email.png';
import facebook from '../../resources/images/facebook.png';
import firebase from '../../resources/images/firebase.png';
import Git from '../../resources/images/git.png';
import github from '../../resources/images/github.png';
import HTML from '../../resources/images/html.png';
import illustrator from '../../resources/images/illustrator.png';
import Java from '../../resources/images/java.png';
import JavaScript from '../../resources/images/javascript.png';
import linkedin from '../../resources/images/linkedin.png';
import malt from '../../resources/images/malt.png';
import Mongo from '../../resources/images/mongo.png';
import Nuxt from '../../resources/images/nuxt.png';
import phone from '../../resources/images/phone.png';
import PHP from '../../resources/images/php.png';
import React from '../../resources/images/react.png';
import Redux from '../../resources/images/redux.png';
import Sass from '../../resources/images/sass.png';
import SQL from '../../resources/images/sql.png';
import stripe from '../../resources/images/stripe.png';
import Swift from '../../resources/images/swift.png';
import Tailwind from '../../resources/images/tailwind.png';
import UXUI from '../../resources/images/uxui.png';
import XD from '../../resources/images/xd.png';

import smartcityImage1 from '../../resources/images/projects/smartcity/image1.png';
import smartcityImage10 from '../../resources/images/projects/smartcity/image10.png';
import smartcityImage11 from '../../resources/images/projects/smartcity/image11.png';
import smartcityImage12 from '../../resources/images/projects/smartcity/image12.png';
import smartcityImage13 from '../../resources/images/projects/smartcity/image13.png';
import smartcityImage14 from '../../resources/images/projects/smartcity/image14.png';
import smartcityImage15 from '../../resources/images/projects/smartcity/image15.png';
import smartcityImage16 from '../../resources/images/projects/smartcity/image16.png';
import smartcityImage2 from '../../resources/images/projects/smartcity/image2.png';
import smartcityImage3 from '../../resources/images/projects/smartcity/image3.png';
import smartcityImage4 from '../../resources/images/projects/smartcity/image4.png';
import smartcityImage5 from '../../resources/images/projects/smartcity/image5.png';
import smartcityImage6 from '../../resources/images/projects/smartcity/image6.png';
import smartcityImage7 from '../../resources/images/projects/smartcity/image7.png';
import smartcityImage8 from '../../resources/images/projects/smartcity/image8.png';
import smartcityImage9 from '../../resources/images/projects/smartcity/image9.png';

import faDashboardImage1 from '../../resources/images/projects/formationacademy/dashboard/image1.png';
import faDashboardImage10 from '../../resources/images/projects/formationacademy/dashboard/image10.png';
import faDashboardImage11 from '../../resources/images/projects/formationacademy/dashboard/image11.png';
import faDashboardImage12 from '../../resources/images/projects/formationacademy/dashboard/image12.png';
import faDashboardImage13 from '../../resources/images/projects/formationacademy/dashboard/image13.png';
import faDashboardImage2 from '../../resources/images/projects/formationacademy/dashboard/image2.png';
import faDashboardImage3 from '../../resources/images/projects/formationacademy/dashboard/image3.png';
import faDashboardImage4 from '../../resources/images/projects/formationacademy/dashboard/image4.png';
import faDashboardImage5 from '../../resources/images/projects/formationacademy/dashboard/image5.png';
import faDashboardImage6 from '../../resources/images/projects/formationacademy/dashboard/image6.png';
import faDashboardImage7 from '../../resources/images/projects/formationacademy/dashboard/image7.png';
import faDashboardImage8 from '../../resources/images/projects/formationacademy/dashboard/image8.png';
import faDashboardImage9 from '../../resources/images/projects/formationacademy/dashboard/image9.png';

import trainDelayImage1 from '../../resources/images/projects/traindelays/image1.png';
import trainDelayImage2 from '../../resources/images/projects/traindelays/image2.png';
import trainDelayImage3 from '../../resources/images/projects/traindelays/image3.png';
import trainDelayImage4 from '../../resources/images/projects/traindelays/image4.png';
import trainDelayImage5 from '../../resources/images/projects/traindelays/image5.png';

import quizzImage1 from '../../resources/images/projects/quizz/image1.jpg';
import quizzVideo1 from '../../resources/images/projects/quizz/video.mp4';

import lensImage1 from '../../resources/images/projects/mylens/image1.jpg';
import lensImage2 from '../../resources/images/projects/mylens/image2.jpg';
import lensImage3 from '../../resources/images/projects/mylens/image3.jpg';

import combeeProImage1 from '../../resources/images/projects/combee/pro/image1.png';
import combeeProImage2 from '../../resources/images/projects/combee/pro/image2.png';
import combeeProImage3 from '../../resources/images/projects/combee/pro/image3.png';
import combeeProImage4 from '../../resources/images/projects/combee/pro/image4.png';
import combeeProImage5 from '../../resources/images/projects/combee/pro/image5.png';
import combeeProImage6 from '../../resources/images/projects/combee/pro/image6.png';
import combeeProImage7 from '../../resources/images/projects/combee/pro/image7.png';
import combeeProImage8 from '../../resources/images/projects/combee/pro/image8.png';
import combeeProImage9 from '../../resources/images/projects/combee/pro/image9.png';

import combeeImage1 from '../../resources/images/projects/combee/image1.png';
import combeeImage10 from '../../resources/images/projects/combee/image10.png';
import combeeImage11 from '../../resources/images/projects/combee/image11.png';
import combeeImage12 from '../../resources/images/projects/combee/image12.png';
import combeeImage13 from '../../resources/images/projects/combee/image13.png';
import combeeImage14 from '../../resources/images/projects/combee/image14.png';
import combeeImage15 from '../../resources/images/projects/combee/image15.png';
import combeeImage16 from '../../resources/images/projects/combee/image16.png';
import combeeImage17 from '../../resources/images/projects/combee/image17.png';
import combeeImage18 from '../../resources/images/projects/combee/image18.png';
import combeeImage19 from '../../resources/images/projects/combee/image19.png';
import combeeImage2 from '../../resources/images/projects/combee/image2.png';
import combeeImage20 from '../../resources/images/projects/combee/image20.png';
import combeeImage21 from '../../resources/images/projects/combee/image21.png';
import combeeImage22 from '../../resources/images/projects/combee/image22.png';
import combeeImage23 from '../../resources/images/projects/combee/image23.png';
import combeeImage3 from '../../resources/images/projects/combee/image3.png';
import combeeImage4 from '../../resources/images/projects/combee/image4.png';
import combeeImage5 from '../../resources/images/projects/combee/image5.png';
import combeeImage6 from '../../resources/images/projects/combee/image6.png';
import combeeImage7 from '../../resources/images/projects/combee/image7.png';
import combeeImage8 from '../../resources/images/projects/combee/image8.png';
import combeeImage9 from '../../resources/images/projects/combee/image9.png';

import hyypeImage1 from '../../resources/images/projects/hyype/computer/image1.png';
import hyypeImage2 from '../../resources/images/projects/hyype/computer/image2.png';

import hyypeImageMobile1 from '../../resources/images/projects/hyype/mobile/1.png';
import hyypeImageMobile2 from '../../resources/images/projects/hyype/mobile/2.png';
import hyypeImageMobile3 from '../../resources/images/projects/hyype/mobile/3.png';
import hyypeImageMobile411 from '../../resources/images/projects/hyype/mobile/4.1.1.png';
import hyypeImageMobile412 from '../../resources/images/projects/hyype/mobile/4.1.2.png';
import hyypeImageMobile413 from '../../resources/images/projects/hyype/mobile/4.1.3.png';
import hyypeImageMobile421 from '../../resources/images/projects/hyype/mobile/4.2.1.png';
import hyypeImageMobile422 from '../../resources/images/projects/hyype/mobile/4.2.2.png';
import hyypeImageMobile423 from '../../resources/images/projects/hyype/mobile/4.2.3.png';
import hyypeImageMobile4 from '../../resources/images/projects/hyype/mobile/4.png';
import hyypeImageMobile5 from '../../resources/images/projects/hyype/mobile/5.png';

import hyypeImagePreview1 from '../../resources/images/projects/hyype/preview/1.png';
import hyypeImagePreview2 from '../../resources/images/projects/hyype/preview/2.png';
import hyypeImagePreview3 from '../../resources/images/projects/hyype/preview/3.png';

import naisImageActivate1 from '../../resources/images/projects/nais/activate/1.png';
import naisImageActivate2 from '../../resources/images/projects/nais/activate/2.png';
import naisImageActivate3 from '../../resources/images/projects/nais/activate/3.png';

import naisImageGame1 from '../../resources/images/projects/nais/game/1.png';
import naisImageGame10 from '../../resources/images/projects/nais/game/10.png';
import naisImageGame11 from '../../resources/images/projects/nais/game/11.png';
import naisImageGame12 from '../../resources/images/projects/nais/game/12.png';
import naisImageGame13 from '../../resources/images/projects/nais/game/13.png';
import naisImageGame14 from '../../resources/images/projects/nais/game/14.png';
import naisImageGame15 from '../../resources/images/projects/nais/game/15.png';
import naisImageGame16 from '../../resources/images/projects/nais/game/16.png';
import naisImageGame17 from '../../resources/images/projects/nais/game/17.png';
import naisImageGame18 from '../../resources/images/projects/nais/game/18.png';
import naisImageGame2 from '../../resources/images/projects/nais/game/2.png';
import naisImageGame3 from '../../resources/images/projects/nais/game/3.png';
import naisImageGame4 from '../../resources/images/projects/nais/game/4.png';
import naisImageGame5 from '../../resources/images/projects/nais/game/5.png';
import naisImageGame6 from '../../resources/images/projects/nais/game/6.png';
import naisImageGame7 from '../../resources/images/projects/nais/game/7.png';
import naisImageGame8 from '../../resources/images/projects/nais/game/8.png';
import naisImageGame9 from '../../resources/images/projects/nais/game/9.png';

import bevoprImage1 from '../../resources/images/projects/bevopr/1.png';
import bevoprImage10 from '../../resources/images/projects/bevopr/10.png';
import bevoprImage11 from '../../resources/images/projects/bevopr/11.png';
import bevoprImage12 from '../../resources/images/projects/bevopr/12.png';
import bevoprImage13 from '../../resources/images/projects/bevopr/13.png';
import bevoprImage2 from '../../resources/images/projects/bevopr/2.png';
import bevoprImage3 from '../../resources/images/projects/bevopr/3.png';
import bevoprImage4 from '../../resources/images/projects/bevopr/4.png';
import bevoprImage5 from '../../resources/images/projects/bevopr/5.png';
import bevoprImage6 from '../../resources/images/projects/bevopr/6.png';
import bevoprImage7 from '../../resources/images/projects/bevopr/7.png';
import bevoprImage8 from '../../resources/images/projects/bevopr/8.png';
import bevoprImage9 from '../../resources/images/projects/bevopr/9.png';

import mailwindImage1 from '../../resources/images/projects/mailwind/1.png';
import mailwindImage10 from '../../resources/images/projects/mailwind/10.png';
import mailwindImage11 from '../../resources/images/projects/mailwind/11.png';
import mailwindImage12 from '../../resources/images/projects/mailwind/12.png';
import mailwindImage13 from '../../resources/images/projects/mailwind/13.png';
import mailwindImage2 from '../../resources/images/projects/mailwind/2.png';
import mailwindImage3 from '../../resources/images/projects/mailwind/3.png';
import mailwindImage4 from '../../resources/images/projects/mailwind/4.png';
import mailwindImage5 from '../../resources/images/projects/mailwind/5.png';
import mailwindImage6 from '../../resources/images/projects/mailwind/6.png';
import mailwindImage7 from '../../resources/images/projects/mailwind/7.png';
import mailwindImage8 from '../../resources/images/projects/mailwind/8.png';
import mailwindImage9 from '../../resources/images/projects/mailwind/9.png';

export const WORK_MISSION_TYPE = {
    front: {
        label: 'Frontend',
        background: '#18dcff',
    },
    back: {
        label: 'Backend',
        background: '#7d5fff',
    },
    uxui: {
        label: 'UI/UX',
        background: '#67e6dc',
    },
    business: {
        label: 'Business Development',
        background: '#3ae374',
    },
    projectManagement: {
        label: 'Project management',
        background: '#1b9cfc',
    },
    mobile: {
        label: 'Mobile development',
        background: '#f78fb3',
    },
    landing: {
        label: 'Landing Page',
        background: '#6c5ce7',
    },
};

export const PROGRAMMING_SKILLS = [
    {
        name: 'HTML',
        level: 'high',
        logo: HTML,
    },
    {
        name: 'SCSS',
        level: 'high',
        logo: Sass,
    },
    {
        name: 'Tailwind',
        level: 'high',
        logo: Tailwind,
    },
    {
        name: 'JavaScript',
        level: 'high',
        logo: JavaScript,
    },
    {
        name: 'Next/React',
        level: 'high',
        logo: React,
    },
    {
        name: 'Redux',
        level: 'high',
        logo: Redux,
    },
    {
        name: 'Nuxt/Vue',
        level: 'high',
        logo: Nuxt,
    },
    {
        name: '.NET',
        level: 'high',
        logo: Dotnet,
    },
    {
        name: 'SQL',
        level: 'high',
        logo: SQL,
    },
    {
        name: 'Firebase',
        level: 'high',
        logo: firebase,
    },
    {
        name: 'Git',
        level: 'high',
        logo: Git,
    },
    {
        name: 'Stripe',
        level: 'high',
        logo: stripe,
    },
    {
        name: 'Azure',
        level: 'medium',
        logo: Azure,
    },
    {
        name: 'MongoDB',
        level: 'medium',
        logo: Mongo,
    },
    {
        name: 'Java',
        level: 'medium',
        logo: Java,
    },
    {
        name: 'PHP',
        level: 'medium',
        logo: PHP,
    },
    {
        name: 'Swift',
        level: 'low',
        logo: Swift,
    },
    {
        name: 'AWS',
        level: 'low',
        logo: AWS,
    },
];

export const DESIGN_SKILLS = [
    {
        name: 'UX | UI',
        level: 'high',
        logo: UXUI,
    },
    {
        name: 'Adobe XD',
        level: 'high',
        logo: XD,
    },
    {
        name: 'Illustrator',
        level: 'low',
        logo: illustrator,
    },
];

export const WORKS = [
    {
        backgroundImage: '#bbd0fa',
        images: [
            { label: 'work.mailwind.image.info.1', image: mailwindImage1 },
            { label: 'work.mailwind.image.video.1', youtube: '9x05ia48RAU' },
            { label: 'work.mailwind.image.info.2', image: mailwindImage2 },
            { label: 'work.mailwind.image.info.3', image: mailwindImage3 },
            { label: 'work.mailwind.image.info.4', image: mailwindImage4 },
            { label: 'work.mailwind.image.info.5', image: mailwindImage5 },
            { label: 'work.mailwind.image.info.6', image: mailwindImage6 },
            { label: 'work.mailwind.image.info.7', image: mailwindImage7 },
            { label: 'work.mailwind.image.info.8', image: mailwindImage8 },
            { label: 'work.mailwind.image.info.9', image: mailwindImage9 },
            { label: 'work.mailwind.image.info.10', image: mailwindImage10 },
            { label: 'work.mailwind.image.info.11', image: mailwindImage11 },
            { label: 'work.mailwind.image.info.12', image: mailwindImage12 },
            { label: 'work.mailwind.image.info.13', image: mailwindImage13 },
        ],
        title: 'Mailwind',
        year: '2023 | ...',
        description: 'work.mailwind.description',
        missions: [WORK_MISSION_TYPE.front, WORK_MISSION_TYPE.back, WORK_MISSION_TYPE.uxui, WORK_MISSION_TYPE.landing, WORK_MISSION_TYPE.business],
        challenges: ['work.mailwind.challenge.1', 'work.mailwind.challenge.2', 'work.mailwind.challenge.3'],
        techs: ['HTML', 'Tailwind', 'Next/React', 'Redux', '.NET', 'Stripe', 'AWS', 'Azure'],
        link: 'https://mailwind.io/',
    },
    {
        backgroundImage: '#fad7d9',
        images: [
            { label: 'work.bevopr.image.info.1', image: bevoprImage1 },
            { label: 'work.bevopr.image.info.2', image: bevoprImage2 },
            { label: 'work.bevopr.image.info.3', image: bevoprImage3 },
            { label: 'work.bevopr.image.info.4', image: bevoprImage4 },
            { label: 'work.bevopr.image.info.5', image: bevoprImage5 },
            { label: 'work.bevopr.image.info.6', image: bevoprImage6 },
            { label: 'work.bevopr.image.info.7', image: bevoprImage7 },
            { label: 'work.bevopr.image.info.8', image: bevoprImage8 },
            { label: 'work.bevopr.image.info.9', image: bevoprImage9 },
            { label: 'work.bevopr.image.info.10', image: bevoprImage10 },
            { label: 'work.bevopr.image.info.11', image: bevoprImage11 },
            { label: 'work.bevopr.image.info.12', image: bevoprImage12 },
            { label: 'work.bevopr.image.info.13', image: bevoprImage13 },
        ],
        title: 'Bevopr',
        year: '2022 | 2023',
        description: 'work.bevopr.description',
        missions: [WORK_MISSION_TYPE.front, WORK_MISSION_TYPE.back, WORK_MISSION_TYPE.uxui, WORK_MISSION_TYPE.landing],
        challenges: ['work.bevopr.challenge.1', 'work.bevopr.challenge.2', 'work.bevopr.challenge.3', 'work.bevopr.challenge.4', 'work.bevopr.challenge.5', 'work.bevopr.challenge.6', 'work.bevopr.challenge.7', 'work.bevopr.challenge.8', 'work.bevopr.challenge.9'],
        techs: ['HTML', 'Tailwind', 'Next/React', 'Redux', '.NET', 'Firebase', 'Stripe', 'AWS'],
        link: 'https://bevopr.io/',
    },
    {
        backgroundImage: '#2828ae',
        images: [
            { label: 'work.nais.image.info.1', image: naisImageActivate1 },
            { label: 'work.nais.image.info.1', image: naisImageActivate2 },
            { label: 'work.nais.image.info.1', image: naisImageActivate3 },

            { label: 'work.nais.image.info.2', image: naisImageGame1 },
            { label: 'work.nais.image.info.2', image: naisImageGame2 },
            { label: 'work.nais.image.info.2', image: naisImageGame3 },
            { label: 'work.nais.image.info.2', image: naisImageGame4 },
            { label: 'work.nais.image.info.3', image: naisImageGame5 },
            { label: 'work.nais.image.info.3', image: naisImageGame6 },
            { label: 'work.nais.image.info.3', image: naisImageGame7 },
            { label: 'work.nais.image.info.3', image: naisImageGame8 },
            { label: 'work.nais.image.info.3', image: naisImageGame9 },
            { label: 'work.nais.image.info.4', image: naisImageGame10 },
            { label: 'work.nais.image.info.4', image: naisImageGame11 },
            { label: 'work.nais.image.info.5', image: naisImageGame12 },
            { label: 'work.nais.image.info.5', image: naisImageGame13 },
            { label: 'work.nais.image.info.5', image: naisImageGame14 },
            { label: 'work.nais.image.info.5', image: naisImageGame17 },
            { label: 'work.nais.image.info.6', image: naisImageGame15 },
            { label: 'work.nais.image.info.6', image: naisImageGame16 },
            { label: 'work.nais.image.info.7', image: naisImageGame18 },
        ],
        title: 'Bot message',
        year: '2021',
        description: 'work.nais.description',
        missions: [WORK_MISSION_TYPE.front, WORK_MISSION_TYPE.back, WORK_MISSION_TYPE.uxui],
        challenges: ['work.nais.challenge.1', 'work.nais.challenge.2'],
        techs: ['HTML', 'SCSS', 'Tailwind', 'Next/React', 'MongoDB', 'Azure'],
    },
    {
        backgroundImage: '#9c5de4',
        images: [
            { label: 'work.hyype.image.info.1', image: hyypeImage1 },
            { label: 'work.hyype.image.info.1', image: hyypeImage2 },

            { label: 'work.hyype.image.info.2', image: hyypeImageMobile1 },
            { label: 'work.hyype.image.info.2', image: hyypeImageMobile2 },
            { label: 'work.hyype.image.info.2', image: hyypeImageMobile3 },
            { label: 'work.hyype.image.info.3', image: hyypeImageMobile4 },
            { label: 'work.hyype.image.info.3', image: hyypeImageMobile411 },
            { label: 'work.hyype.image.info.3', image: hyypeImageMobile412 },
            { label: 'work.hyype.image.info.3', image: hyypeImageMobile413 },
            { label: 'work.hyype.image.info.3', image: hyypeImageMobile421 },
            { label: 'work.hyype.image.info.3', image: hyypeImageMobile422 },
            { label: 'work.hyype.image.info.3', image: hyypeImageMobile423 },
            { label: 'work.hyype.image.info.3', image: hyypeImageMobile5 },

            { label: 'work.hyype.image.info.4', image: hyypeImagePreview1 },
            { label: 'work.hyype.image.info.4', image: hyypeImagePreview2 },
            { label: 'work.hyype.image.info.4', image: hyypeImagePreview3 },
        ],
        title: 'Hyype',
        year: '2021',
        description: 'work.hyype.description',
        missions: [WORK_MISSION_TYPE.front, WORK_MISSION_TYPE.uxui, WORK_MISSION_TYPE.landing],
        challenges: ['work.hyype.challenge.1'],
        techs: ['HTML', 'SCSS', 'Tailwind', 'Next/React'],
    },
    {
        backgroundImage: '#1481ff',
        images: [
            { label: 'work.smartcity.image.info.1', image: smartcityImage1 },
            { label: 'work.smartcity.image.info.1', image: smartcityImage2 },
            { label: 'work.smartcity.image.info.1', image: smartcityImage3 },
            { label: 'work.smartcity.image.info.2', image: smartcityImage4 },
            { label: 'work.smartcity.image.info.1', image: smartcityImage5 },
            { label: 'work.smartcity.image.info.1', image: smartcityImage6 },
            { label: 'work.smartcity.image.info.1', image: smartcityImage7 },
            { label: 'work.smartcity.image.info.1', image: smartcityImage8 },
            { label: 'work.smartcity.image.info.1', image: smartcityImage9 },
            { label: 'work.smartcity.image.info.1', image: smartcityImage10 },
            { label: 'work.smartcity.image.info.1', image: smartcityImage11 },
            { label: 'work.smartcity.image.info.3', image: smartcityImage12 },
            { label: 'work.smartcity.image.info.3', image: smartcityImage13 },
            { label: 'work.smartcity.image.info.3', image: smartcityImage14 },
            { label: 'work.smartcity.image.info.4', image: smartcityImage15 },
            { label: 'work.smartcity.image.info.4', image: smartcityImage16 },
        ],
        title: '360° Smartcity',
        year: '2021 | 2022',
        description: 'work.smartcity.description',
        missions: [WORK_MISSION_TYPE.front, WORK_MISSION_TYPE.uxui],
        challenges: ['work.smartcity.challenge.1', 'work.smartcity.challenge.2', 'work.smartcity.challenge.3', 'work.smartcity.challenge.4', 'work.smartcity.challenge.5'],
        techs: ['HTML', 'SCSS', 'Tailwind', 'Nuxt/Vue'],
    },
    {
        backgroundImage: '#3dc1d3',
        images: [
            { label: 'work.formationacademy.image.info.1', image: faDashboardImage1 },
            { label: 'work.formationacademy.image.info.2', image: faDashboardImage2 },
            { label: 'work.formationacademy.image.info.3', image: faDashboardImage3 },
            { label: 'work.formationacademy.image.info.4', image: faDashboardImage4 },
            { label: 'work.formationacademy.image.info.5', image: faDashboardImage5 },
            { label: 'work.formationacademy.image.info.5', image: faDashboardImage6 },
            { label: 'work.formationacademy.image.info.6', image: faDashboardImage7 },
            { label: 'work.formationacademy.image.info.7', image: faDashboardImage8 },
            { label: 'work.formationacademy.image.info.8', image: faDashboardImage9 },
            { label: 'work.formationacademy.image.info.9', image: faDashboardImage13 },
            { label: 'work.formationacademy.image.info.10', image: faDashboardImage10 },
            { label: 'work.formationacademy.image.info.10', image: faDashboardImage11 },
            { label: 'work.formationacademy.image.info.10', image: faDashboardImage12 },
        ],
        title: 'FormationAcademy',
        year: '2020',
        description: 'work.formationacademy.description',
        missions: [WORK_MISSION_TYPE.front, WORK_MISSION_TYPE.back, WORK_MISSION_TYPE.uxui, WORK_MISSION_TYPE.projectManagement],
        challenges: ['work.formationacademy.challenge.1', 'work.formationacademy.challenge.2', 'work.formationacademy.challenge.3', 'work.formationacademy.challenge.4'],
        techs: ['HTML', 'SCSS', 'Next/React', 'Redux', '.NET', 'Azure'],
        link: 'https://formation.academy/',
    },
    {
        backgroundImage: '#546de5',
        images: [
            { label: 'work.combee.image.info.2', image: combeeProImage1 },
            { label: 'work.combee.image.info.1', youtube: 'jZfm-JwJR6U' },
            { label: 'work.combee.image.info.3', image: combeeProImage2 },
            { label: 'work.combee.image.info.3', image: combeeProImage3 },
            { label: 'work.combee.image.info.4', image: combeeProImage4 },
            { label: 'work.combee.image.info.4', image: combeeProImage5 },
            { label: 'work.combee.image.info.4', image: combeeProImage6 },
            { label: 'work.combee.image.info.4', image: combeeProImage7 },
            { label: 'work.combee.image.info.5', image: combeeProImage8 },
            { label: 'work.combee.image.info.6', image: combeeProImage9 },
            { label: 'work.combee.image.info.7', image: combeeImage1, type: 'contain' },
            { label: 'work.combee.image.info.8', image: combeeImage2, type: 'contain' },
            { label: 'work.combee.image.info.9', image: combeeImage3, type: 'contain' },
            { label: 'work.combee.image.info.10', image: combeeImage4, type: 'contain' },
            { label: 'work.combee.image.info.11', image: combeeImage5, type: 'contain' },
            { label: 'work.combee.image.info.12', image: combeeImage6, type: 'contain' },
            { label: 'work.combee.image.info.13', image: combeeImage7, type: 'contain' },
            { label: 'work.combee.image.info.14', image: combeeImage8, type: 'contain' },
            { label: 'work.combee.image.info.15', image: combeeImage9, type: 'contain' },
            { label: 'work.combee.image.info.16', image: combeeImage10, type: 'contain' },
            { label: 'work.combee.image.info.17', image: combeeImage11, type: 'contain' },
            { label: 'work.combee.image.info.18', image: combeeImage12, type: 'contain' },
            { label: 'work.combee.image.info.19', image: combeeImage13, type: 'contain' },
            { label: 'work.combee.image.info.20', image: combeeImage14, type: 'contain' },
            { label: 'work.combee.image.info.21', image: combeeImage15, type: 'contain' },
            { label: 'work.combee.image.info.22', image: combeeImage16, type: 'contain' },
            { label: 'work.combee.image.info.23', image: combeeImage17, type: 'contain' },
            { label: 'work.combee.image.info.24', image: combeeImage18, type: 'contain' },
            { label: 'work.combee.image.info.24', image: combeeImage19, type: 'contain' },
            { label: 'work.combee.image.info.25', image: combeeImage20, type: 'contain' },
            { label: 'work.combee.image.info.26', image: combeeImage21, type: 'contain' },
            { label: 'work.combee.image.info.27', image: combeeImage22, type: 'contain' },
            { label: 'work.combee.image.info.28', image: combeeImage23, type: 'contain' },
        ],
        title: 'Combee',
        year: '2017 | 2020',
        description: 'work.combee.description',
        missions: [WORK_MISSION_TYPE.front, WORK_MISSION_TYPE.back, WORK_MISSION_TYPE.uxui, WORK_MISSION_TYPE.business, WORK_MISSION_TYPE.projectManagement],
        challenges: ['work.combee.challenge.1', 'work.combee.challenge.2', 'work.combee.challenge.3', 'work.combee.challenge.4'],
        techs: ['HTML', 'SCSS', 'Next/React', 'Redux', '.NET', 'Azure', 'Stripe'],
    },
    {
        backgroundImage: '#f5cd79',
        images: [
            { label: 'work.traindelays.image.info.1', image: trainDelayImage1 },
            { label: 'work.traindelays.image.info.2', image: trainDelayImage2 },
            { label: 'work.traindelays.image.info.3', image: trainDelayImage3 },
            { label: 'work.traindelays.image.info.4', image: trainDelayImage4 },
            { label: 'work.traindelays.image.info.5', image: trainDelayImage5 },
        ],
        title: 'Traindelays',
        year: 'July 2019',
        description: 'work.traindelays.description',
        missions: [WORK_MISSION_TYPE.front, WORK_MISSION_TYPE.uxui],
        challenges: ['work.traindelays.challenge.1'],
        techs: ['HTML', 'SCSS', 'Next/React'],
        link: 'https://traindelays.be/',
    },
    {
        backgroundImage: '#e66767',
        images: [{ image: quizzImage1 }, { video: quizzVideo1 }],
        title: 'Quizz Game',
        year: 'January 2018',
        description: 'work.quizz.description',
        missions: [WORK_MISSION_TYPE.mobile, WORK_MISSION_TYPE.uxui],
        challenges: ['work.quizz.challenge.1'],
        techs: ['Swift', 'Firebase'],
    },
    {
        backgroundImage: '#f8a5c2',
        images: [{ image: lensImage1 }, { image: lensImage2 }, { image: lensImage3 }],
        title: 'MyLens',
        year: 'December 2017',
        description: `work.mylens.description`,
        missions: [WORK_MISSION_TYPE.mobile, WORK_MISSION_TYPE.uxui],
        challenges: ['work.mylens.challenge.1'],
        techs: ['Swift'],
    },
];

export const CONTACT = [
    {
        id: 'facebook',
        icon: facebook,
        link: 'https://www.facebook.com/Clementjanssens/',
    },
    {
        id: 'linkedin',
        icon: linkedin,
        link: 'https://www.linkedin.com/in/clementjanssens/',
    },
    {
        id: 'github',
        icon: github,
        link: 'https://github.com/ClementJanssens',
    },
    {
        id: 'malt',
        icon: malt,
        link: 'https://www.malt.fr/profile/clementjanssens',
    },
    {
        id: 'phone',
        icon: phone,
        link: 'tel:+32492528455',
    },
    {
        id: 'mail',
        icon: email,
        link: 'mailto:clement.janssens@reunit.eu',
    },
];

export const METHOD = [
    {
        title: 'Prototypage (UX/UI)',
        description: 'method.prototype.description',
        tasks: ['method.prototype.objective.1', 'method.prototype.objective.2', 'method.prototype.objective.3'],
    },
    {
        title: 'Développement',
        description: 'method.development.description',
        tasks: ['method.development.objective.1', 'method.development.objective.2', 'method.development.objective.3'],
    },
    {
        title: 'Déployement & Support',
        description: 'method.support.description',
        tasks: ['method.support.objective.1', 'method.support.objective.2', 'method.support.objective.3'],
    },
];

export const CONVERSATION_SENDER = {
    ROBOT: 0,
    USER: 1,
};

export const CONVERSATION = [
    // INTRO
    {
        message: 'chat.intro.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false,
    },
    {
        message: 'chat.intro.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: 'chat.intro.3',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: 'chat.intro.4',
        by: CONVERSATION_SENDER.USER,
        delay: 1000,
        thenWait: true,
        choices: [
            {
                lbl: 'prendre contact avec toi 💬',
                goTo: 4,
            },
            {
                lbl: 'connaître tes tarifs 💶',
                goTo: 10,
            },
            {
                lbl: 'connaître tes disponibilités 📆',
                goTo: 15,
            },
            {
                lbl: 'Fixer un rendez-vous 📍',
                goTo: 20,
            },
        ],
    },

    // CONTACT
    {
        message: 'chat.contact.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false,
    },
    {
        message: '✉️ clement.janssens.ti@gmail.com',
        link: 'mailto:clement.janssens.ti@gmail.com',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: '📱 +32 492 52 84 55',
        link: 'tel:+32492528455',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: 'fb.me/clementjanssens',
        link: 'https://fb.me/clementjanssens',
        messageIcon: facebook,
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: 'linkedin.com/in/clementjanssens/',
        link: 'https://linkedin.com/in/clementjanssens/',
        messageIcon: linkedin,
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: 'chat.contact.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 1000,
        thenWait: true,
        goTo: 23,
    },

    // RATE
    {
        message: 'chat.rate.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false,
    },
    {
        message: 'chat.rate.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 2000,
        thenWait: false,
    },
    {
        message: 'chat.rate.3',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: 'chat.rate.4',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        by: CONVERSATION_SENDER.USER,
        delay: 500,
        thenWait: true,
        choices: [
            {
                lbl: 'chat.choice.1',
                goTo: 4,
            },
            {
                lbl: 'chat.choice.2',
                goTo: 15,
            },
            {
                lbl: 'chat.choice.3',
                goTo: 24,
            },
        ],
    },

    // AVAILABILITY
    {
        message: 'chat.availability.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false,
    },
    {
        message: 'chat.availability.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 2000,
        thenWait: false,
    },
    {
        message: 'chat.availability.3',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: 'chat.availability.4',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        by: CONVERSATION_SENDER.USER,
        delay: 500,
        thenWait: true,
        choices: [
            {
                lbl: 'chat.choice.1',
                goTo: 4,
            },
            {
                lbl: 'chat.choice.4',
                goTo: 10,
            },
            {
                lbl: 'chat.choice.3',
                goTo: 24,
            },
        ],
    },

    // MAKE APPOINTMENT
    {
        message: 'chat.appointment.1',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 0,
        thenWait: false,
    },
    {
        message: 'chat.appointment.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        message: 'calendly.com/clement-janssens/meeting',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: true,
        link: 'https://calendly.com/clement-janssens/meeting',
        goTo: 23,
    },

    // LOOP
    {
        message: 'chat.loop.1',
        by: CONVERSATION_SENDER.USER,
        delay: 500,
        thenWait: true,
        choices: [
            {
                lbl: 'Oui',
                goTo: 2,
            },
            {
                lbl: 'Non',
                goTo: 24,
            },
        ],
    },
    {
        message: 'chat.loop.2',
        by: CONVERSATION_SENDER.ROBOT,
        delay: 500,
        thenWait: false,
    },
    {
        by: CONVERSATION_SENDER.USER,
        delay: 0,
        thenWait: true,
        choices: [
            {
                lbl: 'chat.choice.5',
                goTo: 2,
            },
        ],
    },
];

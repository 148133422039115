import propTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-scroll';
import { MENU } from './resources/constants/menu';

const DotNavigation = ({ currentPage, scrollToTop, t }) => {
    return (
        <div className='dot-navigation'>
            {MENU.map(m =>
                <Link key={m.id} onClick={m.position === 0 ? scrollToTop : null} to={m.id} spy={true} smooth={true} duration={500}>
                    <div className={`dot transition ${currentPage === m.position ? 'selected' : ''}`}>
                        <div className='overlay rounded-s transition'>
                            {t(m.label)}
                        </div>
                    </div>
                </Link>)}
        </div>
    );
}

DotNavigation.propTypes = {
    currentPage: propTypes.number.isRequired,
    scrollToTop: propTypes.func.isRequired
};

export default withNamespaces()(DotNavigation);
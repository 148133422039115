import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Element } from 'react-scroll';
import { METHOD } from '../resources/constants/content';
import { MENU } from '../resources/constants/menu';
import ItemMethodPanel from './method/ItemMethodPanel';

const MethodPanel = ({ blurValue, t, methodPanelRef, methodOverlay1Ref, methodOverlay2Ref, methodHighlight1Ref, methodHighlight2Ref, methodHighlight3Ref }) => {
    return (
        <section className='page no-scroll-bar' ref={methodPanelRef}>
            <Element name={MENU[3].id}>
                <div className='content'>
                    <div className='title container flex align'>
                        <h1>
                            <div className='line'>
                                <div className='white-line-effect'>
                                    <div className='overlay transition' ref={methodOverlay1Ref}></div>
                                </div>
                                <span className='highlighter selected'><span className='text'>{t("Think")}</span><div className='highlight transition' ref={methodHighlight1Ref}></div></span>. <span className='highlighter selected'><span className='text'>{t("Make")}.</span><div className='highlight transition' ref={methodHighlight2Ref}></div></span><br />
                            </div>

                            <div className='line'>
                                <div className='white-line-effect'>
                                    <div className='overlay transition' ref={methodOverlay2Ref}></div>
                                </div>
                                <span className='highlighter selected'><span className='text'>{t("Solve")}</span><div className='highlight transition' ref={methodHighlight3Ref}></div></span>.
                            </div>
                        </h1>
                    </div>

                    <div className='container methods'>
                        {METHOD.map((method, index) => <ItemMethodPanel key={index} method={method} showItems={blurValue > 0} position={index + 1} />)}
                    </div>
                </div>
            </Element>
        </section>
    );
}

export default withNamespaces()(MethodPanel);
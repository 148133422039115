import React from 'react';
import propTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const ItemMethodPanel = ({ method, showItems, position, t }) => {
    return (
        <div className={`method shadow rounded-m opacity-fade-effect ${showItems ? 'visible' : ''}`} style={{ transitionDelay: `${position * 0.5}s` }}>
            <div className='icon'></div>

            <h3>{t(method.title)}</h3>

            <p>{t(method.description)}</p>

            <ul>
                {method.tasks.map((task, index) => <li key={index}>{t(task)}</li>)}
            </ul>
        </div>
    );
}

ItemMethodPanel.propTypes = {
    method: propTypes.object.isRequired
};

export default withNamespaces()(ItemMethodPanel);
import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { Element } from 'react-scroll';
import { WORKS } from '../resources/constants/content';
import { MENU } from '../resources/constants/menu';
import ItemWorkPanel from './work/ItemWorkPanel';

const WorkPanel = ({ blurValue, t, workPanelRef, workOverlay1Ref, workOverlay2Ref, workHighlight1Ref, workHighlight2Ref }) => {
    const [seeMoreProjects, setSeeMoreProjects] = useState(false);

    return (
        <section className='page no-scroll-bar' ref={workPanelRef}>
            <Element name={MENU[1].id}>
                <div className='content'>
                    <div className='title container flex align'>
                        <h1>
                            <div className='line'>
                                <div className='white-line-effect'>
                                    <div className='overlay transition' ref={workOverlay1Ref}></div>
                                </div>
                                <div className='highlighter selected'>
                                    <span className='text'>{t("The best work")}</span>
                                    <div className='highlight transition' ref={workHighlight1Ref}></div>
                                </div> <span className='text'>{t("I did,")}</span>
                            </div>

                            <div className='line'>
                                <div className='white-line-effect'>
                                    <div className='overlay transition' ref={workOverlay2Ref}></div>
                                </div>
                                <span className='text'>{t("and")}</span> <div className='highlighter selected'>
                                    <span className='text'>{t("business")}</span>
                                    <div className='highlight transition' ref={workHighlight2Ref}></div>
                                </div> <span className='text'>{t("I helped")}.</span>
                            </div>
                        </h1>
                    </div>

                    <div className='container work'>
                        {WORKS.slice(0, 5).map((work, index) => <ItemWorkPanel key={index} work={work} showItems={blurValue > 0} position={index}  />)}

                        {!seeMoreProjects && <div className='more-button'>
                            <button className='default primary rounded-s transition' onClick={() => setSeeMoreProjects(true)}>
                                <span className='main-text transition'>{t("See more projects")} 💻</span>
                            </button>
                        </div>}

                        {seeMoreProjects && <div className='more-projects'>
                            {WORKS.slice(5, WORKS.length).map((work, index) => <ItemWorkPanel key={index} work={work} showItems={blurValue > 0} position={index}  />)}
                        </div>}

                    </div>
                </div>
            </Element>
        </section>
    );
}

export default withNamespaces()(WorkPanel);
import React from 'react';
import propTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';

const ChatChoices = ({ choices, messageId, showMessageIndex, t }) => {
    return (
        <div className='choices-container'>
            <div className='choices'>
                {choices.map((choice, key) => <button key={key} className='default secondary rounded-s transition' onClick={() => showMessageIndex(messageId, t(choice.lbl), choice.goTo)}><span className='main-text transition'>{t(choice.lbl)}</span></button>)}
            </div>
        </div>
    );
}

ChatChoices.propTypes = {
    choices: propTypes.array.isRequired
};

export default withNamespaces()(ChatChoices);
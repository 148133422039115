import React, { useEffect, useRef, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { CONVERSATION } from '../../resources/constants/content';
import ChatBubbleContainer from './ChatBubbleContainer';

const Chat = ({ t, show }) => {
    const [messages, setMessages] = useState([]);
    const [iMessage, setIMessage] = useState(0);
    const chatRef = useRef();

    const displayMessage = (i, msg) => {
        const message = CONVERSATION[i];

        if (message) {
            setTimeout(() => {
                const newMsg = [...msg, { id: Date.now(), ...message }];
                const newIndex = i + 1;

                setMessages(newMsg);
                setIMessage(newIndex);

                if (!message.thenWait) {
                    displayMessage(newIndex, newMsg);
                } else if (message.goTo) {
                    displayMessage(message.goTo, newMsg);
                }

            }, message.delay || 0);
        }
    }

    const groupMessages = (messages) => {
        let sortedMessages = [];
        let senderTypeSaved = null;
        let i = 0;

        while (i < messages.length) {
            let message = messages[i];
            senderTypeSaved = message.by;
            let group = [];

            while (i < messages.length && message.by === senderTypeSaved) {
                group.push(message);
                senderTypeSaved = message.by;
                i++;
                message = messages[i];
            }

            sortedMessages = [...sortedMessages, group];
        }

        return sortedMessages;
    }

    const showMessageIndex = (messageId, newMessageText, goTo) => {
        const newMessages = [...messages];

        const messageToUpdateIndex = newMessages.findIndex(m => m.id === messageId);

        newMessages[messageToUpdateIndex] = { ...newMessages[messageToUpdateIndex], message: `${t(newMessages[messageToUpdateIndex]?.message) || ''} ${newMessageText || ''}`, choices: [], input: null };
        setMessages(newMessages);
        displayMessage(goTo, newMessages);
    }

    useEffect(() => {
        displayMessage(iMessage, messages);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        chatRef.current.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: 'smooth'
        });
    })

    return (
        <div className={`chat rounded-l opacity-fade-effect ${show ? 'visible' : ''}`} ref={chatRef}>
            {groupMessages(messages).map((gm, index) => <ChatBubbleContainer key={index} bubblesContainer={gm} showMessageIndex={showMessageIndex} />)}
        </div>
    );
}

export default withNamespaces()(Chat);